<template>
	<div class="animated fadeIn">
		<b-card title="Inventory Summary Report"
			sub-title="Summary of all assets in supply chain (asset owner)/warehouse (distributor/manufacturer)">
			<b-container class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<b-card>
					<b-row>
						<b-col md="12" sm="12" class="mt-1 mb-2">
							<b>FILTER OPTIONS</b>
						</b-col>
					</b-row>

					<!-- Company -->
					<b-row no-gutters>
						<b-col lg="4" md="10" sm="12" class="mr-2">
							<b-form-group label="Accountable Company">
								<v-select name="Accountable Company" class="style-chooser" label="text"
									:options="filterByOptions.companyItems" :reduce="(company) => company.value"
									v-model="filterBy.company" v-validate="'selectRequired'">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a company
										</em>
									</template>
								</v-select>
								<span v-show="errors.has('Accountable Company')" class="help-block">
									{{ errors.first('Accountable Company') }}
								</span>
							</b-form-group>
						</b-col>
						<b-col lg="4" md="10" sm="12" class="mr-2">
							<b-form-group label="Asset Current Location">
								<v-select class="style-chooser" label="text" :options="filterByOptions.storageLocationItems"
									:reduce="(loc) => loc.value" v-model="filterBy.storageLocation">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a location
										</em>
									</template>
								</v-select>
							</b-form-group>
						</b-col>
					</b-row>

					<!-- Asset -->
					<b-row no-gutters>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Asset Type">
								<v-select class="style-chooser" label="text" :options="filterByOptions.assetTypeItems"
									:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
									<template v-slot:no-options="{ search, searching }">
										<template v-if="searching">
											No results found for
											<em>
												<strong>{{ search }}</strong>
											</em>
										</template>
										<em :style="{ opacity: 0.5 }" v-else>
											Start typing to search for a asset type
										</em>
									</template>
								</v-select>
							</b-form-group>
						</b-col>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group class="font-weight-normal" label="Asset Status">
								<b-form-select name="Status" v-model="filterBy.status"
									:options="filterByOptions.statusItems" v-validate="'required'" />
								<span v-show="errors.has('Status')" class="help-block">{{
									errors.first('Status')
								}}</span>
							</b-form-group>
						</b-col>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group class="font-weight-normal" label="Asset Condition">
								<b-form-select v-model="filterBy.condition" :options="filterByOptions.conditionItems" />
							</b-form-group>
						</b-col>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Asset Code">
								<b-form-input name="Asset Code" type="search" v-model="filterBy.assetCode"
									v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" placeholder />
								<span v-show="errors.has('Asset Code')" class="help-block">{{
									errors.first('Asset Code')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row no-gutters>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Last Inventory Date From">
								<b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
									label-reset-button="Clear" :date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}" :date-disabled-fn="dateFromDisabled" />
							</b-form-group>
						</b-col>
						<b-col lg="4" md="5" sm="12" class="mr-2">
							<b-form-group label="Last Inventory Date To">
								<b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
									label-reset-button="Clear" :date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}" :date-disabled-fn="dateFromDisabled" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row no-gutters>
						<b-col sm="12">
							<b-button class="mr-1" variant="success" @click="onGenerate">
								Generate
							</b-button>
							<b-button class="mr-1" variant="primary" @click="resetFilters">
								Reset
							</b-button>
						</b-col>
					</b-row>
				</b-card>

				<div v-if="items.length > 0">
					<div class="btn-table-options">
						<b-row>
							<b-col sm="6" md="3">
								<b-dropdown text=" Select Actions " variant="dark" slot="append">
									<b-dropdown-item>
										<json-excel :data="exportData" :fields="exportFields" type="xls"
											:name="fileName + '.xls'">Export Report in Excel</json-excel>
									</b-dropdown-item>
									<b-dropdown-item>
										<json-excel :data="exportData" :fields="exportFields" type="csv"
											:name="fileName + '.csv'">Export Report to CSV</json-excel>
									</b-dropdown-item>
								</b-dropdown>
							</b-col>
							<b-col sm="6" md="3" offset-md="6" class="my-1 text-md-right">
								<b-input-group prepend="Show" append="/ Page">
									<b-form-select :options="pageOptions" v-model="perPage" />
								</b-input-group>
							</b-col>
						</b-row>
					</div>
					<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" responsive>
						<template v-slot:cell(status)="row">
							<span v-if="row.item.status == 'Inactive'">
								<b-badge variant="secondary">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'Stored'">
								<b-badge variant="primary">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'In-Transit'">
								<b-badge variant="success">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'In-Repair'">
								<b-badge variant="info">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'Lost'">
								<b-badge variant="danger">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'BER'">
								<b-badge variant="dark">
									{{ row.item.status }}
								</b-badge>
							</span>
						</template>
					</b-table>
					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="totalDisplay">Total: {{ totalRows }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
								class="my-0" />
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-card> No Results Found. </b-card>
				</div>
			</b-container>
		</b-card>
	</div>
</template>

<script>
// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import reportApi from '@/api/reportApi';

// Others
import JsonExcel from 'vue-json-excel';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'inventory-summary-report',
	components: { JsonExcel, Loading },
	data() {
		return {
			items: [],
			fields: [],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				company: config.companyDefaultValue,
				storageLocation: config.storageLocationDefaultValue,
				assetType: config.assetTypeDefaultValue,
				status: null,
				condition: null,
				assetCode: '',
				dateFrom: null,
				dateTo: null,
			},
			filterBy: {
				company: config.companyDefaultValue,
				storageLocation: config.storageLocationDefaultValue,
				assetType: config.assetTypeDefaultValue,
				status: null,
				condition: null,
				assetCode: '',
				dateFrom: null,
				dateTo: null,
			},
			prevFilterBy: null,

			filterByOptions: {
				companyItems: [],
				storageLocationItems: [],
				conditionItems: config.assetConditionStatus,
				statusItems: [
					{
						value: null,
						text: ' - Please select - ',
					},
					{
						value: 'Stored',
						text: 'Stored',
					},
					{
						value: 'In-Transit',
						text: 'In-Transit',
					},
					{
						value: 'Lost',
						text: 'Lost',
					},
				],
				assetTypeItems: [],
			},

			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		tableColumns() {
			let fields = [
				{
					key: 'assetCode',
					sortable: true,
				},
				{
					key: 'assetType',
					sortable: false,
				},

				{
					key: 'status',
					sortable: false,
				},
				{
					key: 'condition',
					sortable: false,
				},
				{
					key: 'currentUser',
					label: "Current Company User",
					sortable: false,
				},
				{
					key: 'branchCode',
					sortable: false,
				},
				{
					key: 'currentLocation',
					sortable: false,
				},
				{
					key: 'locationDays',
					label: 'Location Days',
					sortable: true,
				},
				{
					key: 'assetAge',
					label: 'Asset Age',
					sortable: true,
				},
				{
					key: 'activationDate',
					label: 'Activation Date',
				}
			];

			let permissions = this.loggedUserCompany.permissions;
			if (permissions && permissions.inventory) {
				fields.push({
					key: 'assetName',
					sortable: false,
				});

				fields.push({
					key: 'serialNo',
					sortable: false,
				});

				fields.push({
					key: 'description',
					sortable: false,
				});

				fields.push({
					key: 'condition',
					sortable: false,
				});

				fields.push({
					key: 'prevInventorySessionId',
					label: 'Previous Inventory Session ID',
					sortable: false,
				});

				fields.push({
					key: 'prevInventoryDate',
					label: 'Previous Inventory Date',
					sortable: false,
				});

				fields.push({
					key: 'prevInventoriedBy',
					label: 'Previous Inventoried By',
					sortable: false,
				});

				fields.push({
					key: 'currInventorySessionId',
					label: 'Current Inventory Session ID',
					sortable: false,
				});

				fields.push({
					key: 'currInventoryDate',
					label: 'Current Inventory Date',
					sortable: false,
				});

				fields.push({
					key: 'currInventoriedBy',
					label: 'Current Inventoried By',
					sortable: false,
				});
			}

			if (permissions && permissions.dispatch) {
				fields.push({
					key: 'receiverLocation',
					sortable: false,
				});

				fields.push({
					key: 'Truck Company',
					sortable: false,
				});

				fields.push({
					key: 'Truck Driver',
					sortable: false,
				});

				fields.push({
					key: 'Plate No/CR/OR',
					sortable: false,
				});
			}

			return fields;
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			let fields = {
				'Asset Code': 'assetCode',
				'Asset Type': 'assetType',
				Status: 'status',
				'Current Company User': 'currentUser',
				'Branch Code': 'branchCode',
				'Current Location': 'currentLocation',
				'Location Days': 'locationDays',
				'Asset Age': 'assetAge',
				'Activation Date': 'activationDate',
			}

			let permissions = this.loggedUserCompany.permissions;
			if (permissions && permissions.inventory) {
				fields = Object.assign(fields, {
					'Asset Name': 'assetName',
					'Serial No': 'serialNo',
					Description: 'description',
					Condition: 'condition',
					'Previous Inventory Session ID': 'prevInventorySessionId',
					'Previous Inventory Date': 'prevInventoryDate',
					'Previous Inventoried By': 'prevInventoriedBy',
					'Current Inventory Session ID': 'currInventorySessionId',
					'Current Inventory Date': 'currInventoryDate',
					'Current Inventoried By': 'currInventoriedBy',
				});
			}
			if (permissions && permissions.dispatch) {
				fields = Object.assign(fields, {
					'Receiver Location': 'receiverLocation',
					'Truck Company': 'Truck Company',
					'Truck Driver': 'Truck Driver',
					'Plate No/CR/OR': 'Plate No/CR/OR',
				});
			}

			return fields;
		},

		fileName() {
			let filename = 'InventorySummary';

			if (this.filterBy.company.id) {
				filename += `_${this.filterBy.company.name}`;
			}

			if (this.filterBy.company.id && this.filterBy.storageLocation.id) {
				filename = `${this.filterBy.company.name}_${this.filterBy.storageLocation.name}`;
			}

			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return `${filename}_${DateUtil.getDateInMMDDYYYYFormat(currTimeStamp)}`;
		},
	},
	async mounted() {
		// show loading indicator
		this.isLoading = true;

		try {
			this.fields = this.tableColumns;

			this.allCompaniesObj = this.$store.getters.companies;
			this.allStorageLocationsObj = this.$store.getters.storageLocations;
			this.allAssetTypesObj = this.$store.getters.assetTypes;

			if (!this.isSuperAdmin) {
				let connectedStorageLocationsObj =
					this.$store.getters.connectedStorageLocations;
				this.allStorageLocationsObj = Object.assign(
					{},
					this.allStorageLocationsObj,
					connectedStorageLocationsObj
				);
			}

			this.filterByOptions.companyItems =
				DropDownItemsUtil.retrieveCompanyItems(this.allCompaniesObj, this.loggedUserCompany);

			this.filterByOptions.storageLocationItems =
				DropDownItemsUtil.retrieveStorageLocationItems(
					this.allStorageLocationsObj
				);

			this.filterByOptions.assetTypeItems =
				DropDownItemsUtil.retrieveAssetTypeItems(this.allAssetTypesObj);

			this.resetFilters();
		} catch (error) {
			this.$toaster.error('Error loading data. Please reload the page again.');
		}

		// hide loading indicator
		this.isLoading = false;
	},
	methods: {
		resetFilters() {
			this.items = [];
			this.filterBy = Object.assign({}, this.defaultFilterBy);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		isValidAssetCode(assetCode) {
			let assetTypesObj = this.$store.getters.assetTypes;
			return (
				_.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(assetTypesObj, assetCode)
			);
		},
		async validateFilter() {
			let isValid = await this.$validator.validateAll();

			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return isValid;
			}

			if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(
					`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
				);
				isValid = false;
			} else if (
				(_.isEmpty(this.filterBy.dateFrom) &&
					!_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))
			) {
				this.$toaster.warning(
					'Invalid Date Range. Date From and Date To must both have value.'
				);
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning(
					'Invalid Date Range. Date From must be less than Date To.'
				);
				isValid = false;
			} else if (
				DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90
			) {
				this.$toaster.warning(
					'Invalid Date Range. Data range is allowed up to 90 days difference.'
				);
				isValid = false;
			}

			return isValid;
		},
		getReportParameters() {
			let filter = Object.assign({}, this.filterBy);
			filter.companyId = this.loggedUserCompany.id;

			if (this.filterBy.dateFrom) {
				filter.fromTimestamp = DateUtil.startDateTimeStamp(
					new Date(this.filterBy.dateFrom)
				);
			}
			if (this.filterBy.dateTo) {
				filter.toTimestamp = DateUtil.endDateTimeStamp(
					new Date(this.filterBy.dateTo)
				);
			}

			return filter;
		},
		async onGenerate() {
			let isValid = await this.validateFilter();
			if (!isValid) {
				return;
			}

			// Reset items
			this.items = [];
			// Show loader
			this.isLoading = true;

			try {
				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
				let reportParam = this.getReportParameters();
				let { data } = await reportApi.getInventorySummaryReport(
					reportParam,
					view,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					let items = data.items;

					if (_.isEmpty(items)) {
						this.$toaster.warning('No Results found.');
					} else {
						this.items = items;
						this.totalRows = this.items.length;
					}

					// Hide loader
					this.isLoading = false;
					// Mark the prevFilter
					this.prevFilter = Object.assign({}, this.filterBy);
				} else {
					this.$toaster.error(
						'Error loading data. Please reload the page again.'
					);
					// hide loading indicator
					this.isLoading = false;
				}
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);

				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
};
</script>

<style scoped></style>
